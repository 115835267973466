import { Navigate } from 'react-router-dom';

export function ProtectedRoute({ children }) {
  const accessToken = sessionStorage.getItem('access_token');

  if (!accessToken) {
    return <Navigate to="/" />;
  }

  return children;
}
