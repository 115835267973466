import React from "react";
import Button from "../reusable/Button";

export default function Login() {
  // Generate Code Verifier and Code Challenge
  function base64UrlEncode(buffer) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }
  function generateCodeVerifier() {
    const array = new Uint32Array(32);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join(
      ""
    );
  }

  async function generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest("SHA-256", data);
    return base64UrlEncode(digest);
  }

  //   Authorization Request (Redirect to Authorization Server)
  async function redirectToAuthServer() {
    const codeVerifier = generateCodeVerifier();
    const strrr ="0e0ed685fd67ffb561cf542706a82210e039ed498c15d5f60f8d4b8fed736895";
    console.log("code verifier==>", codeVerifier)
    console.log("str length==>", strrr.length)
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    // Store the codeVerifier in localStorage/sessionStorage for later use
    sessionStorage.setItem("code_verifier", codeVerifier);

    const clientId = "9d1dcec9-2775-45c1-abed-6a31d75a80f2"; // `${REACT_APP_CLIENT_ID}/profile`
    const redirectUri = "https://cf.siddhisoftwares.com/callback";
    const authEndpoint = "https://askcf.cloudframe.com/oauth/authorize";
    // const scope = "openid profile email";
    const scope = "";
    const responseType = "code";
    const params = {
            'client_id' : "9d1dcec9-2775-45c1-abed-6a31d75a80f2",
            'redirect_uri' : 'https://cf.siddhisoftwares.com/callback',
            'response_type' : 'code',
            'scope' : '',
            // 'state' : $state,
            'code_challenge' : codeChallenge,
            'code_challenge_method' : 'S256',
    }
    const query = new URLSearchParams(params).toString();
    // const authUrl = `${authEndpoint}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(
    //   redirectUri
    // )}&scope=${encodeURIComponent(
    //   scope
    // )}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
    const authUrl = `${authEndpoint}?${query}`;

    window.location.href = authUrl;
  }

  return (
    <div className="px-3 flex items-center justify-center HeightHome">
      <Button className="!px-11 !py-2 !rounded-full flex items-center gap-1 flex-wrap" onClick={redirectToAuthServer}>
        <span>Login with</span>
        <img className="w-28" src="./assets/logo-white.webp" alt="logo" />
      </Button>
    </div>
  );
}
