import React, { useEffect } from "react";

export default function ProtectedPage() {
  //   Access Protected Resources
  async function fetchProtectedResource() {
    const accessToken = sessionStorage.getItem("access_token");

    try {
      const response = await fetch(
        "https://api.your-resource-server.com/protected",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = await response.json();
      console.log("Protected resource data:", data);
    } catch (error) {
      console.error("Error fetching protected resource", error);
    }
  }

  useEffect(() => {
    fetchProtectedResource();
  }, []);

  return <div>Protected content</div>;
}
