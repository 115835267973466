import React from "react";

export default function Button(props) {
  const { type, name, className, disabled, onClick } = props;
  return (
    <button
      {...props}
      onClick={onClick}
      disabled={disabled}
      type={type}
      name={name}
      className={`py-1 px-6 rounded-md outline-none bg-cfBlue hover:bg-opacity-80 transition-all text-White active:bg-opacity-10 ${className}`}
    />
  );
}
