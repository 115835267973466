import { useContext } from "react";
import { createGlobalStyle } from "styled-components";
import { UserContext } from "./components/UserContext";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Header from "./components/pages/Header";
// import Home from "./components/pages/Home";
import { ProtectedRoute } from "./components/pages/ProtectedRoute";
import { Callback } from "./components/Oauth2/Callback";
import Login from "./components/pages/Login";
import ProtectedPage from "./components/pages/ProtectedPage";

const GlobalStyle = createGlobalStyle`
body{
  background: ${({ darkTheme }) => (darkTheme ? "#212121" : "#eeeeee")};
  color: ${(props) => (props.darkTheme ? "#fffd" : "black")};
}
`;

function App() {
  const { darkTheme } = useContext(UserContext);

  // ctrl + k Keyboard =======
  // const handleKeyPress = (event) => {
  //   if (event.ctrlKey && event.key === "k") {
  //     event.preventDefault();
  //     setIsSearchOpen((prevVisible) => !prevVisible);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyPress);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, []);

  return (
    // <div className="max-w-[1440px] mx-auto relative">
    <div className="relative">
      <GlobalStyle darkTheme={darkTheme} />

      <Header />

      <div className="max-w-[1440px] mx-auto ">
        <Router>
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<Login />} />
            <Route path="/callback" element={<Callback />} />
            <Route
              path="/protected"
              element={
                <ProtectedRoute>
                  <ProtectedPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
