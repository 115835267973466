import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function Callback() {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      exchangeCodeForToken(code);
    } else {
      console.error("Authorization code not found");
    }
  }, []);

  async function exchangeCodeForToken(code) {
    const codeVerifier = sessionStorage.getItem("code_verifier");
    const tokenEndpoint = "https://askcf.cloudframe.com/oauth/token";

    const data = {
      grant_type: "authorization_code",
      code: code,
      redirect_uri: "https://cf.siddhisoftwares.com/callback",
      client_id: "9d1dcec9-2775-45c1-abed-6a31d75a80f2",
      code_verifier: codeVerifier,
    };

    const formBody = Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");

    try {
      const response = await fetch(tokenEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      });

      const tokenData = await response.json();
      console.log("Token response", tokenData);

      if (tokenData.access_token) {
        sessionStorage.setItem("access_token", tokenData.access_token);
        navigate("/protected");
      } else {
        console.error("Token exchange failed");
      }
    } catch (error) {
      console.error("Error exchanging code for token", error);
    }
  }

  return <div>Loading...</div>;
}
