import { createContext, useState } from "react";

const UserContext = createContext({});

const UserContextProvider = ({ children }) => {
  const [darkTheme, setDarkTheme] = useState(false);
  const [isSmMenuOpen, setIsSmMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <UserContext.Provider
      value={{
        darkTheme,
        setDarkTheme,
        isSmMenuOpen,
        setIsSmMenuOpen,
        isSearchOpen,
        setIsSearchOpen,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
